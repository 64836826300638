import React from 'react';

export default class Notice extends React.Component {

  render() {
    return (
      <>
        <h2><strong>データ更新</strong></h2><br className="sp"/><h3>令和6年10月1日(火)</h3>
        <ul>
          <li>指定道路図の情報を令和6年9月11日現在に更新しました。</li>
          <li>土地改良区の表示は令和5年6月29日更新時に廃止しました。<br />
              土地改良区については、各土地改良区事務所もしくは名古屋市緑政土木局都市農業課（電話052-972-2497）へお問い合わせください。</li>
          <li>次回更新は令和6年12月下旬頃を予定しています。</li>
        </ul>
        <br />
      </>
    );
  }

}