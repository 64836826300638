import React from 'react';

export default class Notice extends React.Component {

  render() {
    return (
      <>
        <h2><strong>システム更新</strong></h2><br className="sp"/><h3>令和6年10月1日(火)</h3>
        <ul>
          <li>建築情報マップを令和6年9月11日現在に更新しました。</li>
          <li>前回更新時（令和6年6月20日）からの変更はありません。</li>
          <li>次回更新は令和6年12月下旬頃を予定しています。</li>
        </ul>
        <br />
      </>
    );
  }

}